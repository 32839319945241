<template>
  <div class="guikehui-card">
    <van-cell-group>
      <van-field
        ref="guikehuiInput"
        v-model="form.mobile"
        label="手机号"
        placeholder="手机号"
        maxlength="11"
        type="number"
      >
        <template #button>
          <van-button
            v-if="card.list.length > 1"
            size="small"
            type="info"
            :disabled="smsCode.downtime < 60"
            style="width: 90px;"
            @click="handleReSelectCard"
          >重新选卡</van-button>
        </template>
      </van-field>
      <van-field
        v-model="form.code"
        label="验证码"
        placeholder="验证码"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            :disabled="smsCode.downtime < 60 || form.mobile.length < 11"
            style="width: 90px;"
            @click="handleSendGuikehuiSms(false)"
          >{{ smsCode.downtime < 60 ? `${smsCode.downtime}s` : '发送验证码' }}</van-button>
        </template>
      </van-field>
    </van-cell-group>

    <!--  弹窗  -->
    <van-popup
      v-model="card.show"
      position="bottom"
      :style="{ height: 'auto', minHeight: '120px' }"
      class="guikehui-modal"
    >
      <div class="guikehui-modal__title">选择支付卡</div>
      <van-radio-group
        v-model="cardVal"
        @change="handleCardChange"
      >
        <van-cell-group>
          <van-cell
            v-for="item in card.list"
            :key="item.cardNo"
            title-class="pay-type__title"
            @click="handleSelectCard(item)"
          >
            <template #title>
              <div class="guikehui-cell-title">
                <div class="name"> {{ item.cardNo }}&nbsp;&nbsp;{{ item.cardLevelDescript }}</div>
                <div class="desc">(余额：&yen;{{ item.balanceUsable }})</div>
              </div>
            </template>
            <template #icon>
              <van-icon
                name="card"
                size="22px"
              />
            </template>
            <template #right-icon>
              <van-radio
                :name="item.cardId"
                checked-color="#0AA650"
              />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'GuikehuiCard',
  props: {
    payInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        mobile: '',
        code: '',
        verifyNo: '',
        member: {}
      },
      smsCode: {
        interval: null,
        downtime: 60
      },
      cardVal: '',
      // 贵客荟多卡的情况
      card: {
        show: false,
        isRe: false,
        list: []
      },
      cardMember: {}
    }
  },
  watch: {
    'form.mobile'() {
      this.card.list = []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.guikehuiInput.focus()
    })
  },
  methods: {
    handleSelectCard(item) {
      this.cardVal = item.cardId
    },
    handleReSelectCard() {
      this.card.show = true
      this.card.isRe = true
    },
    handleCardChange(cardId) {
      const data = this.card.list.filter(obj => obj.cardId === cardId)
      this.cardMember = data[0]
      this.form.member = data[0]
      this.card.isRe = false
      this.handleSendGuikehuiSms(true)
    },
    async handleSendGuikehuiSms(isSelect = false) {
      if (this.form.mobile.length < 11) {
        this.$toast('请填写正确的手机号！')
        return
      }
      if (this.smsCode.interval) {
        return
      }
      if (!isSelect) {
        this.cardMember = {}
        await this.getcardMember()
        if (!this.cardMember.memberId) {
          return
        }
      }

      const member = this.cardMember
      if (member.balanceUsable < this.payInfo.real_fee) {
        this.$dialog.alert({
          title: '余额不足',
          message: `当前会员可用余额不足(&yen;${member.balanceUsable})\n请更换其他会员账号`
        }).then(() => {

        })
        return
      }
      this.guikehuiSendSms()
    },
    guikehuiSmsDowntime() {
      if (this.smsCode.interval) return
      this.smsCode.interval = setInterval(() => {
        if (this.smsCode.downtime-- <= 0) {
          clearInterval(this.smsCode.interval)
          this.smsCode.interval = null
          this.smsCode.downtime = 60
        }
      }, 1000)
    },
    async getcardMember() {
      this.$toast.loading({ forbidClick: true, message: '会员信息查询中...' })
      await this.$api.pay.guikehuiQueryMember(this.form.mobile).then(res => {
        this.$toast.clear()
        if (res.data.length > 1) {
          this.card.list = res.data
          this.card.show = true
        } else {
          this.cardMember = res.data[0]
        }
      }).catch(() => {
        this.$toast.clear()
      })
    },
    guikehuiSendSms() {
      this.card.show = false
      const member = this.cardMember
      this.$dialog.confirm({
        title: '会员余额',
        message: `当前会员可用余额(&yen;${member.balanceUsable})`
      }).then(res => {
        this.$toast.loading({ forbidClick: true, message: '发送中...' })
        this.$api.pay.guikehuiSendSms(this.form.mobile).then(res => {
          this.$toast('获取成功')
          this.$toast.clear()
          this.form.verifyNo = res.data
          this.$emit('success', this.form)
          --this.smsCode.downtime
          this.guikehuiSmsDowntime()
        }).catch(() => { this.$toast.clear() })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.guikehui-card{
  margin-top: -1px;
}
.guikehui-modal{
  &__title{
    text-align: center;
    font-size: 1rem;
    padding: .8rem 0;
    color: #666;
  }
  .guikehui-cell-title{
    text-align: left;
    padding-left: .4rem;
    .name{

    }
    .desc{
      color: #999;
      font-size: .8rem;
    }
  }
}
</style>
