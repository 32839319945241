import { render, staticRenderFns } from "./index.vue?vue&type=template&id=25f21e0d&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=25f21e0d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.11.1_css-loader@6.8.1_vue-template-compiler@2.7.15_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25f21e0d",
  null
  
)

export default component.exports