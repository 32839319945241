/**
 * Created by JianJia.Zhou<jianjia.zhou> on 2023/12/07.
 */
import request from '@/utils/request'
import { getToken } from '@/utils/auth'

export function alipayInfo() {
  return request({
    url: `/passport/pay/alipay/info`,
    method: 'get'
  })
}

export function alipayLogin(data) {
  return request({
    url: `/passport/pay/alipay/login`,
    method: 'post',
    data
  })
}

export function wechatInfo() {
  return request({
    url: `/passport/pay/wechat/info`,
    method: 'get'
  })
}

export function wechatLogin(data) {
  return request({
    url: `/passport/pay/wechat/login`,
    method: 'post',
    data
  })
}

export function localLogin() {
  return request({
    url: `/passport/pay/local/login`,
    method: 'post',
    data: { auth_code: 'xxxx' }
  })
}

export function isLogin() {
  return !!getToken()
}

export default {
  alipayInfo,
  alipayLogin,
  wechatInfo,
  wechatLogin,
  localLogin,
  isLogin
}
