<template>
  <layout-view>
    <van-overlay
      :show="true"
      :custom-style="{backgroundColor: 'rgba(255,255,255, 1)'}"
      flex="main:center cross:center"
    >
      <van-loading
        type="spinner"
        color="#000"
      >
        登录中...
      </van-loading>
    </van-overlay>
  </layout-view>
</template>

<script>
import { setToken } from '@/utils/auth'
import Cache from '@/utils/cache'
import LayoutView from '@/components/Layout'

const IS_WECHAT = (/micromessenger/i).test(navigator.userAgent)
const IS_ALIPAY = (/AlipayClient/i).test(navigator.userAgent)
export default {
  name: 'AuthorizeView',
  components: { LayoutView },
  data() {
    return {
      isWechat: IS_WECHAT,
      isAlipay: IS_ALIPAY,
      redirect: 'payment/0/mch'
    }
  },
  computed: {
    isInsideApp() {
      return this.isWechat || this.isAlipay
    }
  },
  created() {
    if (process.env.NODE_ENV === 'development') {
      this.local()
    }
    this.isInsideApp && this.authorize()
  },
  methods: {
    authorize() {
      if (this.isWechat) {
        this.wechat()
      } else {
        this.alipay()
      }
    },
    alipay() {
      const { auth_code } = this.$route.query
      this.$api.user.alipayLogin({ auth_code }).then(res => {
        const token = res.data.token
        setToken(token)
        const redirect = Cache.get('redirect')
        Cache.remove('redirect')
        window.location.href = redirect || this.redirect
      })
    },
    wechat() {
      const { code } = this.$route.query
      this.$api.user.wechatLogin({ auth_code: code }).then(res => {
        const token = res.data.token
        setToken(token)
        const redirect = Cache.get('redirect')
        Cache.remove('redirect')
        window.location.href = redirect || this.redirect
      })
    },
    local() {
      this.$api.user.localLogin().then(res => {
        const token = res.data.token
        setToken(token)
        const redirect = Cache.get('redirect')
        Cache.remove('redirect')
        window.location.href = redirect || this.redirect
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Authorize {
}
</style>
