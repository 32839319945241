<template>
  <layout-view />
</template>

<script>
import LayoutView from '@/components/Layout'
const IS_WECHAT = (/micromessenger/i).test(navigator.userAgent)
const IS_ALIPAY = (/AlipayClient/i).test(navigator.userAgent)
export default {
  name: 'LoginView',
  components: { LayoutView },
  data() {
    return {
      isWechat: IS_WECHAT,
      isAlipay: IS_ALIPAY
    }
  },
  created() {
    if (process.env.NODE_ENV !== 'development') {
      this.authorize()
    } else {
      this.$router.push('/authorize')
    }
  },
  methods: {
    authorize() {
      if (this.isWechat) {
        this.wechat()
      } else if (this.isAlipay) {
        this.alipay()
      }
    },
    alipay() {
      this.$api.user.alipayInfo().then(res => {
        const { url } = res.data
        window.location.href = url
      })
    },
    wechat() {
      this.$api.user.wechatInfo().then(res => {
        const { url } = res.data
        window.location.href = url
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Authorize {
}
</style>
